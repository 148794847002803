body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #1c83eb; /* Background color */
    color: #ffffff; /* Text color */
    overflow: hidden; /* Prevents scrollbars from showing due to snowflakes */
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
}

.flash-text-container {
    position: relative;
    z-index: 1; /* Ensure text is on top of snowflakes */
    margin-top: 20%;
    text-align: center;
}

.flash-text {
    animation: flash 1s infinite;
    font-size: 5.5rem;
    color: #ffffff;
}

@keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

/* Snowflake styles */
#snow-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow interaction with text */
    z-index: 0; /* Place snowflakes behind text */
}

.snowflake {
    animation: snowflake infinite linear;
    position: absolute;
    top: -20px;
    width: 20px;
    height: 20px;
    background-color: rgb(8, 16, 246);
    border-radius: 50%;
    opacity: 0.8;
    animation-name: fall;
    animation-timing-function: linear;
}

@keyframes fall {
    to {
        transform: translateY(100vh);
    }
}
